import React from "react";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div className="contact h-screen">
      <div className="container mx-auto pt-24 px-16 lg:px-48">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.2 },
          }}
        >
          <h1 className="text-3xl pb-4 lg:text-5xl">Contact</h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.4 },
          }}
        >
          <p>
            For further information, drop me an{" "}
            <a
              href="mailto:hello@anniebartlett.dev?subject=Hello!"
              className="underline"
            >
              email
            </a>{" "}
            here.
          </p>
        </motion.div>
      </div>
    </div>
  );
};
export default Contact;
