import React from "react";
import Index from "./pages/index";

const App = () => {
  return (
    <div>
      <Index />
    </div>
  );
};

export default App;
