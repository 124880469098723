import React from "react";
import { motion } from "framer-motion";

const Banner: React.FC<{}> = () => {
  return (
    <div className="banner">
      <div className="h-64 lg:h-96 xl:h-96 relative">
        <div className="banner-text absolute py-16 px-16 md:px-24 lg:px-36 lg:py-32 xl:px-72 2xl:px-80">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.2 },
            }}
          >
            <h1 className="title text-4xl lg:text-7xl pb-4">Hello!</h1>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.4 },
            }}
          >
            <p className="tagline text-xl lg:text-2xl">
              I'm Annie, a Software Engineer from London.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
