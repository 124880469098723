import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const Navbar: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <header className="navbar py-6 md:px-8">
        <nav className="flex items-center justify-between px-8 lg:px-16">
          <Link to="/" aria-label="homepage">
            <h1 className="logo text-2xl font-bold">anniebartlett</h1>
          </Link>

          <ul className="items-center hidden space-x-10 sm:flex">
            {/*            <li>
              <NavLink to="/about" className="text-sm">
                About
              </NavLink>
            </li>*/}
            <li>
              <NavLink to="/portfolio" className="text-sm">
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink to="/skills" className="text-sm">
                Skills
              </NavLink>
            </li>
            {/*            <li>
              <NavLink to="/contact" className="text-sm">
                Contact
              </NavLink>
            </li>*/}
          </ul>

          {/* Mobile menu button Start */}
          <div className="flex items-center mr-2 sm:hidden">
            <button
              className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md focus:outline-none"
              aria-label="Menu"
              aria-expanded="false"
              onClick={handleClick}
            >
              {/* Icon when menu is closed. */}
              <svg
                className={`block w-6 h-6 ${open ? "hidden" : "block"}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Icon when menu is open. */}
              <svg
                className={`block w-6 h-6 ${open ? "block" : "hidden"}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {/* Mobile menu button Off */}
        </nav>

        {/* Mobile menu open */}
        {open ? (
          <div className={`${open ? "block" : "hidden"} sm:hidden"`}>
            <div className="flex flex-col py-2 px-10">
              {/*              <NavLink to="/about" className="text-sm pb-2">
                About
              </NavLink>*/}
              <NavLink to="/portfolio" className="text-sm pb-2">
                Portfolio
              </NavLink>
              <NavLink to="/skills" className="text-sm pb-2">
                Skills
              </NavLink>
              {/*              <NavLink to="/contact" className="text-sm pb-2">
                Contact
              </NavLink>*/}
            </div>
          </div>
        ) : null}
        {/* Mobile menu off */}
      </header>
    </ClickAwayListener>
  );
};

export default Navbar;
