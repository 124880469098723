import React from "react";
import Banner from "../components/banner";
import ProfileImg from "../assets/images/profile.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div className="main">
      <Banner />
      <div className="container mx-auto md:flex justify-between pb-24 py-8 px-16 lg:px-48">
        <div className="main-content mb-16 md:mr-16 lg:mr-24">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.6 },
            }}
          >
            <h2 className="text-2xl pb-4">It's so nice to meet you.</h2>
            <p>
              I'm Annie. I currently work as a Software Engineer specialising in
              Ruby on Rails and React.
            </p>
            <p>
              My interest in coding began a few years back when I attended my
              first codebar session. It was so exciting to writing my first line
              of code and seeing the results, and I instantly knew that this was
              something I wanted to do.
            </p>
            <p>
              Fast forward to April 2020, I decided to follow my dreams and
              pursue a career in web development. Back then, with a 10-week-old
              baby in arms, I joined the Le Wagon bootcamp and spent six months
              learning the fundamentals of code whilst on maternity leave.
            </p>
            <p>
              My previous work experience is in Fashion Merchandising, where I
              spent the past seven years working at Asos.
            </p>
            <p>
              In my spare time, I work as a teacher/TA at Le Wagon (giving back
              to the community!). I also love creating fun, accessible websites,
              but nowadays usually you'll find me chasing after my little boy
              Henry.
            </p>
            {
              <div>
                Feel free to browse some of my{" "}
                <Link to="/portfolio" className="underline">
                  work
                </Link>
                , view my{" "}
                <Link to="/skills" className="underline">
                  {" "}
                  skills.
                </Link>
              </div>
            }
          </motion.div>
        </div>
        <div className="banner-image right-0">
          <div className="profile w-54 md:w-72">
            <img src={ProfileImg} alt="profile" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
