import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="about h-screen">
      <div className="container mx-auto pt-24 px-16 lg:px-48">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.2 },
          }}
        >
          <h1 className="text-3xl pb-4 lg:text-5xl">About me</h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.4 },
          }}
        >
          <h2 className="text-xl pb-4">
            TLDR; A coder, a bootcamp teacher, and a mum to a cheeky little boy
            called Henry.
          </h2>
        </motion.div>
      </div>
    </div>
  );
};
export default About;
