import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const Footer: React.FC<{}> = () => {
  return (
    <div className="footer h-48 py-8 px-16 md:px-24 lg:px-56 xl:px-72 2xl:px-80">
      <div className="h-full">
        <h4 className="text-2xl pb-2">want to stay in touch?</h4>
        <button className="transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-100 pb-4">
          <a href="mailto:hello@anniebartlett.dev?subject=Hello!">
            <h5>hello@anniebartlett.dev</h5>
          </a>
        </button>
        <br />

        <div className="inline text-2xl">
          <button className="transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-100 pb-4">
            <a
              href="https://github.com/anniebartlett"
              target="blank"
              className="no-underline"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </button>
        </div>

        <div className="inline text-2xl px-1 py-1 m-1">
          <button className="transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-100 pb-4">
            <a
              href="https://linkedin.com/in/anniebartlett"
              target="blank"
              className="no-underline"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
