import React from "react";
import { motion } from "framer-motion";

const Skills = () => {
  return (
    <div className="skills md:h-screen">
      <div className="container mx-auto pt-24 px-16 lg:px-48">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.2 },
          }}
        >
          <h1 className="text-3xl pb-6 lg:text-5xl">Skills</h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.4 },
          }}
        >
          <div className="skills-content">
            <h2 className="text-xl pb-4">What I've learnt so far.</h2>
            <p>
              My journey in tech has been filled with lots of amazing learning
              opportunities.
            </p>
            <p>
              In my day-to-day, you'll find me building features, fixing bugs
              and interacting with different stakeholders in the company to
              ensure the product is in its best shape.
            </p>
            <p>
              I like to think of myself as a good problem solver, where my main
              goal is to provide the most suitable solution to the issue at
              hand.
            </p>
            <p>
              For personal projects, I love being creative and making apps that
              are fun and accessible.
            </p>
            <p>
              Here's a selection of relevant techonologies that I enjoy working
              with:
            </p>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.4 },
          }}
        >
          <div className="flex flex-col md:flex-row md:space-x-16 pt-16">
            <div className="languages mb-5">
              <ul>
                <div className="title px-2">
                  <li className="uppercase font-bold text-lg">Languages</li>
                </div>
                <li className="pl-2">Ruby</li>
                <li className="pl-2">JavaScript (ES6)</li>
                <li className="pl-2">CSS3 (SCSS)</li>
                <li className="pl-2">HTML5</li>
              </ul>
            </div>

            <div className="methods mb-5">
              <ul>
                <div className="title px-2">
                  <li className="uppercase font-bold text-lg">
                    Methods & Tools
                  </li>
                </div>
                <li className="pl-2">Webpack, NPM, Yarn</li>
                <li className="pl-2">Git</li>
                <li className="pl-2">Heroku</li>
                <li className="pl-2">TDD, Rspec</li>
                <li className="pl-2">SOLID, OOP</li>
              </ul>
            </div>

            <div className="frameworks mb-5">
              <ul>
                <div className="title px-2">
                  <li className="uppercase font-bold text-lg">
                    Frameworks & Libs
                  </li>
                </div>
                <li className="pl-2">Rails</li>
                <li className="pl-2">PostgreSQL</li>
                <li className="pl-2">React, jQuery</li>
                <li className="pl-2">Node</li>
                <li className="pl-2">Bootstrap</li>
                <li className="pl-2">Tailwind</li>
              </ul>
            </div>

            <div className="additional mb-5">
              <ul>
                <div className="title px-2">
                  <li className="uppercase font-bold text-lg">Additional</li>
                </div>
                <li className="pl-2">Photoshop, Figma, Sketch</li>
                <li className="pl-2">Agile workflows</li>
              </ul>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default Skills;
