import React from "react";
import { motion } from "framer-motion";

const Portfolio = () => {
  return (
    <div className="portfolio h-screen">
      <div className="container mx-auto pt-24 px-16 lg:px-48">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.2 },
          }}
        >
          <h1 className="text-3xl pb-6 lg:text-5xl">Portfolio</h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { delay: 0.4 },
          }}
        >
          <div className="skills-content">
            <h2 className="text-xl pb-4">What I've built so far.</h2>
            <p>
              I'm currently in the process of rebuilding this part of the site,
              but if you have any questions feel free to{" "}
              <a
                href="mailto:hello@anniebartlett.dev?subject=Hello!"
                className="underline"
              >
                get in touch
              </a>{" "}
              for more info.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
export default Portfolio;
