import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home";
import About from "./about";
import Portfolio from "./portfolio";
import Skills from "./skills";
import Contact from "./contact";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollToTop from "../components/scroll";

const Index = () => {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/skills" component={Skills} />
          <Route path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default Index;
